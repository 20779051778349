@import '../../../assets/styles/variables';

.app-table-billing-white-list {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
}

.app-company-white-list-hover-white {
  cursor: pointer;
  &:hover {
    img {
      filter: $img-primary-white-filter;
    }
  }
}

.app-company-white-list-icon {
  width: 26px;
  height: 24px;

  svg {
    width: 26px;
    height: 24px;
  }
}
