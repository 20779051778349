@import '../../../assets/styles/variables';

.app-white-list-email {
  display: flex;
  padding: 13px 22px;
  flex-wrap: wrap;
  background: white;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 24px;
  border-radius: 12px;

  span {
    cursor: default;
  }

  svg:hover {
    filter: $img-primary-white-filter;
    cursor: pointer;
  }
}
