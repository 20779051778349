@import '../../assets/styles/variables.scss';

.app-table-blocked-acconts {
  margin-top: 52px;

  .app-table-content-row, .app-table-header-row {
    display: grid;
    grid-template-columns: 250px 1fr 170px 30px;
  }

  .app-table-content-row {
    padding-top: 7px;
    padding-bottom: 7px;
    align-items: center;
  }

  .app-table-header-row {
    .app-table-header-row-account-status-column {
      padding-left: 18px;
    }
  }
}

.app-company-account-status {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  border-radius: 18px;
  width: 152px;
  height: 36px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &.app-company-account-status-paid {
    color: $primary-green-color;
    background-color: hexToRGBA($primary-green-color, 0.3);
  }

  &.app-company-account-status-unpaid {
    color: $secondary-red-color;
    background-color: hexToRGBA($secondary-red-color, 0.3);
  }
}
