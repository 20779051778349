$primary-white-color: #ffffff;
$primary-gray-color: #ededed;
$primary-gray-color2: #CECECE;
$primary-green-color: #56aa1c;
$primary-black-color: #3c3c3b;
$primary-black-color2: #4B4B4B;

$secondary-gray-color: #9c9c9c;
$secondary-red-color: #ce171d;
$secondary-yellow-color: #f3a50d;
$secondary-orange-color: #de6634;
$secondary-blue-color: #2e6d96;

$required-mark-color: #FF0000;

$border-color: #00000029;
$popup-header-border: #F1F1F1;
$img-primary-green-filter: invert(44%) sepia(81%) saturate(437%) hue-rotate(52deg) brightness(109%) contrast(96%);
$img-primary-white-filter: invert(89%) sepia(100%) saturate(0%) hue-rotate(338deg) brightness(104%) contrast(102%);

$default-font-size: 16pt;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$menuChangedPx: 800px;

@mixin link-borders {
  $border-radius-px: 12px;
  box-shadow: 0 1px 1px $border-color;
}

$toolbarHeight: 80px;
$mobileToolbarHeight: 25px;
$mobileNavHeight: 52px;
$tabsHeight: 53px;

@mixin link-hover {
  background-color: $primary-gray-color;
  color: $primary-green-color;
  @include link-borders;
}

@mixin link-active {
  color: $primary-white-color;
  background: $primary-green-color;
  @include link-borders;
}

@mixin bold16 {
  font: normal normal 600 16px/22px 'myriad-pro';
}

@mixin semi24 {
  font: normal normal 600 24px/34px 'myriad-pro';
}

@mixin segoe9 {
  font: normal normal normal 9px/12px Segoe UI;
}

@mixin bold26 {
  font: normal normal 600 26px/37px 'myriad-pro';
}

@mixin formControlFont {
  font: normal normal normal 20px/20px 'myriad-pro';
}

@mixin formControlFocused {
  border: 1px solid $primary-green-color;
}

@function hexToRGBA($hex, $alpha) {
  @return rgba(red($hex), green($hex), blue($hex), $alpha);
}
