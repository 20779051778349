@import '../../../assets/styles/variables';

.app-select-date-input {
  background: white;
  padding: 15px;
  width: 98px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  border-radius: 12px;


  &.app-select-date-input-focus {
    outline: 1px solid $primary-green-color;
  }
}

.app-month-range-picker-month-row {
  justify-content: space-between;
  display: flex;
  font-size: 16px;

  .app-month-range-picker-month-background {
    width: 33.3%;

    &.app-month-range-picker-month-background-first,
    &.app-month-range-picker-start-date {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    &.app-month-range-picker-month-background-last,
    &.app-month-range-picker-end-date {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &.app-month-range-picker-start-date,
    &.app-month-range-picker-end-date {
      background: $primary-gray-color;
    }

    &.app-month-range-picker-in-range {
      background: $primary-gray-color;
    }

    &.app-month-range-picker-disabled {
      color: $primary-gray-color2;
    }
  }

  .app-month-range-picker-month {
    padding: 6px 12px;
    cursor: pointer;
    text-align: center;

    &.app-month-range-picker-start-date,
    &.app-month-range-picker-end-date {
      background: $primary-green-color;
      color: $primary-white-color;
      border-radius: 12px;
    }

    &.app-month-range-picker-disabled {
      cursor: default;
    }
  }

}

.app-month-range-picker-apply-button {
  max-width: 144px;
  margin: 0 auto;
  margin-top: 14px;
  text-transform: uppercase;
}
