@import '../../../assets/styles/variables';

.app-table.app-table-diagnostics-sizes {
  margin-top: 30px;

  .app-table-header-row,
  .app-table-content-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .app-table-content {
    max-height: 360px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    height: 100%;

    .app-table-content-row {
      &:hover {
        cursor: pointer;
        color: $primary-green-color;
        background: hexToRGBA($primary-white-color, 0.75);
      }

      &.app-table-content-row-active {
        background: $primary-green-color;
        color: $primary-white-color;
      }
    }
  }
}

.app-diagnostics-call-tracker-card {
  background: $primary-white-color;
  padding: 38px 48px 55px 48px;
  border-radius: 36px;

  button {
    margin-bottom: 52px;
  }
}

.app-diagnostics-call-tracker {
  display: flex;
  gap: 30px;

  .app-diagnostics-call-tracker-event {
    height: 83px;
    width: 90%;
    border-radius: 12px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.app-diagnostics-call-tracker-event-disabled,
    &.app-diagnostics-call-tracker-event-not-processed {
      background: #ECECEC;
      color: hexToRGBA($primary-black-color, 0.5);
    }

    &.app-diagnostics-call-tracker-event-success {
      background: $primary-green-color;
      color: $primary-white-color;
    }

    &.app-diagnostics-call-tracker-event-error {
      background: $secondary-red-color;
      color: $primary-white-color;
    }
  }

  .app-diagnostics-call-tracker-troubleshooting {
    background: #ECECEC;
    color: hexToRGBA($primary-black-color, 0.5);
    border-radius: 12px;
    padding: 30px;
    color: $primary-black-color;
    font-size: 20px;
    margin-top: 12px;
    height: calc(100% - 192px);
    display: flex;
    flex-direction: column;
    gap: 20px;

    ul {
      margin: 0;
    }
  }
}
