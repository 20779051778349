@import 'variables';
@import 'ui-kit';
@import 'utils';

@font-face {
    font-family: 'myriad-pro';
    src: url('../fonts/MYRIADPRO-REGULAR.woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'myriad-pro';
  src: url('../fonts/MYRIADPRO-SEMIBOLD.woff');
  font-weight: 600;
  font-style: normal;
}

html, body {
  padding: 0;
  margin: 0;
  font-family: 'myriad-pro', sans-serif;
  color: $primary-black-color;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #C3C3C363;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background: $primary-black-color;
}

.app {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .app-navigation-desktop-container {
    display: flex;
    flex-direction: column;
    width: 290px;
    min-height: 100vh;
    box-shadow: 0 3px 6px $border-color;

    .app-navigation-close {
      display: none;
    }

    .app-navigation-overlay {
      display: none;
    }

    .app-navigation-logo {
      padding: 30px 28px;

      img {
        max-height: 54px;
      }
    }
  }

  .app-portal {
    width: calc(100vw - 150px);
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }

  .app-navigation-mobile {
    display: none;
  }
}

@media (max-width: $menuChangedPx) {
  .app-popup-content {
    width: 80%;
  }

  .app {
    flex-wrap: wrap;

    .app-navigation-mobile {
      background-color: $primary-white-color;
      height: $mobileNavHeight;
      padding: 0 16px;
      display: flex;
      align-items: center;
      width: 100%;
      box-shadow: 0 1px 1px #00000029;

      img {
        height: 26px;
        margin-left: 9px;
      }
    }

    .app-table {
      .app-table-header-row, .app-table-content-row {
        font-size: 13pt;
      }
    }

    .app-navigation-visible-state:not(:checked) + .app-navigation-desktop-container {
      display: none;
    }

    .app-navigation-visible-state:checked + .app-navigation-desktop-container {
      display: flex;
    }

    .app-navigation-desktop-container {
      position: fixed;
      z-index: 1;
      background: $primary-white-color;
      width: 264px;

      .app-navigation-close {
        position: fixed;
        top: 0;
        left: 264px;
        padding: 8px 10px;
        cursor: pointer;
        background: $primary-white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
      }

      .app-navigation-overlay {
        background: #888888;
        opacity: 0.6;
        position: fixed;
        height: 100vh;
        right: 0;
        width: calc(100% - 264px);
        display: block;
        z-index: 1;
      }
    }

    .app-mw-500 {
      max-width: 300px;
    }

    .app-portal {
      width: 100%;
      height: calc(100% - $mobileNavHeight);
    }

    .app-content {
      padding: 16px;

      .app-systems-table-header {
        margin-bottom: 23px;

        .app-search {
          margin-right: 10px;
        }
      }

      .app-content-footer .app-table-total:not(:last-child) {
        margin-bottom: 0;
      }

      .app-content-footer .app-table-total:first-child {
        margin-top: 0;
      }

      .app-content-footer-pagination {
        justify-content: center;
      }
    }

    .app-content-toolbar {
      padding-left: 16px;
      padding-right: 16px;
      height: $mobileToolbarHeight;

      .app-toolbar-breadcrumps + div {
        transform: translateY(-35px);
      }

      .app-toolbar-breadcrump {
        font-size: 16px;
      }

      img[alt=back] {
        height: 13px;
      }

      .app-toolbar-breadcrump-separator {
        display: inline-block;
        height: 42px;
        svg {
          height: 13px;
        }
      }
    }

    .app-mobile-form {
      flex-direction: column;
      justify-content: center;
      padding: 16px;

      .app-form-section-title {
        font-size: 12pt;
      }

      .app-w-521 {
        width: unset;
      }
    }
  }
}

@media(max-width: 1227px) {
  .app-systems-hardware-header {
    flex-wrap: wrap;
    margin-bottom: 23px;

    .app-search {
      margin-bottom: 10px;
      width: 100%;
    }

    .app-search + div {
      justify-content: flex-end;
      width: 100%;
      margin-right: 10px;
    }
  }

  .app-help-popup-content {
    width: 70%;
    min-height: 746px;
    height: unset;

    .app-ml-99 {
      margin-left: 0;
    }

    .app-help-popup-content-header {
      margin-left: 28px;
    }

    .app-single-video-container {
      width: 90%;
    }

    .app-single-video {
      width: 100%;
      height: 492px;
    }

    .app-mt-26 {
      flex-wrap: wrap;
      margin-top: 0;
      button {
        margin-top: 26px;
      }
    }
  }
}
