@import "../../assets/styles/variables";

$good-color: #56AA1C;
$warn-color: #FEA336;
$error-color: #CB161C;

.app-table-service-health {
  height: calc(100% - 180px)
}

.app-service-health-svg {
  span {
    display: flex;
    align-items: center;
    height: 31px;
  }

  svg {
    width: 31px;
    height: 31px;
  }
}

.app-service-health-row-status-icon-good {
  color: $good-color;
  background-color: hexToRGBA($good-color, 0.3);
}

.app-service-health-row-status-icon-warn {
  color: $warn-color;
  background-color: hexToRGBA($warn-color, 0.3);
}

.app-service-health-row-status-icon-error {
  color: $error-color;
  background-color: hexToRGBA($error-color, 0.3);
}

.app-service-health-expand-triangle-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;

  .app-service-health-expand-triangle {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 14px solid $primary-black-color;

    &.app-service-health-expand-triangle-expanded {
      transform: rotate(180deg);
    }
  }
}

.app-service-health-row-details {
  background-color: hexToRGBA(#FAFAFA, 0.75);
  margin-top: -16px;
  margin-bottom: 20px;
  padding: 23px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  .app-service-health-expanded-row {
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    gap: 9px;

    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-color: $primary-green-color;
      border-radius: 50%;
    }
  }
}

.app-service-health-banner-wrapper {
  .app-service-health-banner {
    display: flex;
    gap: 30px;
    align-items: center;
    border-radius: 12px;
    font-size: 20px;
    line-height: 24px;

    &.app-service-health-banner-good {
      background-color: hexToRGBA($good-color, 0.3);
    }

    &.app-service-health-banner-warn {
      background-color: hexToRGBA($warn-color, 0.3);
    }

    &.app-service-health-banner-error {
      background-color: hexToRGBA($error-color, 0.3);
    }
  }

  &.app-service-health-banner-wrapper-single {

    .app-service-health-banner {
      padding: 30px;
    }
  }

  &.app-service-health-banner-wrapper-many {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .app-service-health-banner {
      padding: 8px 56px;
      gap: 37px;
    }
  }
}
