@import '../../assets/styles/variables';

.app-transfer-site-page {
  .app-transfer-site-title {
    text-transform: uppercase;
    font: normal normal 600 24px/34px 'myriad-pro';
    color: $primary-black-color;
  }

  .app-transfer-site-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 30px;
    height: 100%;
    overflow: hidden;
  }

  .app-transfer-site-buttons {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }

  .app-transfer-site-two-column-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 110px;
  }

  .app-transfer-site-container-left, .app-transfer-site-container-right {
    display: flex;
    flex: 1;
    max-width: 500px;
  }

  .app-transfer-site-list-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 10px;
    overflow: hidden;

    .app-transfer-site-list-left, .app-transfer-site-list-right {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 500px;
      gap: 8px;
    }

    .app-transfer-site-list-center {
      display: flex;
      align-self: center;
      max-width: 90px;
      max-height: 70px;
    }
  }

  .app-form-control .app-select {
    input[type=text]::placeholder {
      opacity: 0.8;
      text-transform: capitalize;
    }
  }

  .app-state-wrapper {
    width: 100%;
  }

  .app-form-control-select-system {
    flex: 1;
    height: 100%;
    overflow: hidden;

    .app-transfer-site-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        background-color: white;
        border-radius: 12px;
        overflow: auto;
      }

    .app-form-control-input {
      align-items: flex-start;
      overflow: hidden;
    }
  }

  .app-transfer-site-toggle-all-systems {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 75px;

    .app-round-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }
  }

  span {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
  }

  .app-transfer-site-checkbox-container {
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    svg {
      width: 21px;
      height: 21px;
      margin-right: 12px;
    }

    .app-round-checkbox {
      margin-right: 12px;
    }
  }

  .app-transfer-site-new-installer-system-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    div {
      display: flex;
      align-items: center;

      svg {
        width: 21px;
        height: 21px;
        margin-right: 12px;
      }
    }

    .app-transfer-site-cross-icon {
      cursor: pointer;
    }
  }
}

input.app-transfer-list-search:disabled::placeholder{
  opacity: 1 !important;
}