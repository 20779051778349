@import '../../../assets/styles/variables';

.app-home-widget {
  background-color: $primary-white-color;
  border-radius: 12px;
  padding: 20px 28px;
  white-space: nowrap;
  box-sizing: border-box;
}

.app-home-widget-call-rate {
  margin-right: 15px;
}

.app-home-widget-users {
  width: 448px;
  
  .app-home-users-count {
    font-weight: bold;
    font-size: 40px;
    text-align: center;
  }

  .app-home-users-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 155px;
      height: 88px;
    }
  }

}

.app-home-widget-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .app-home-widget-title-text {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
}

.app-home-widget-dropdowns {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between;
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;

  svg {
    width: 9px;
    height: 9px;
  }

  .app-select {
    .app-select-items {
      top: 100%;
      right: 0;
      left: auto;

      .app-select-item {
        font-size: 9px;
        line-height: 9px;
      }
    }
  }
}

.app-color-block {
  width: 10px;
  height: 10px;
  margin-right: 16px;
  border-radius: 50%;

  &.app-sales-totals-barchart-legend-item-color1 {
    background-color: #DE6634;
  }

  &.app-sales-totals-barchart-legend-item-color2 {
    background-color: #2E6D96;
  }
}

