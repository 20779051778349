@import 'variables';

.app-d-flex {
  display: flex;
}

.app-pr-20 {
  padding-right: 20px;
}

.app-pt-62 {
  padding-top: 62px;
}

.app-pb-33 {
  padding-bottom: 33px;
}

.app-mb-25 {
  margin-bottom: 25px;
}

.app-pt-20 {
  padding-top: 20px;
}

.app-pb-20 {
  padding-bottom: 20px;
}

.app-mr-20 {
  margin-right: 20px;
}

.app-mr-30 {
  margin-right: 30px;
}

.app-text-align-right {
  text-align: right;
}

.app-text-align-center {
  text-align: center;
}

.app-mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.app-pointer {
  cursor: pointer;
}

.app-mw-527 {
  max-width: 527px;
}

.app-flex-column {
  flex-direction: column;
}

.app-grow-1 {
  flex-grow: 1;
}

.app-justify-content-between {
  justify-content: space-between;
}

.app-justify-content-center {
  justify-content: center;
}

.app-place-center {
  place-content: center;
}

.app-justify-content-end {
  justify-content: end;
}

.app-align-items-center {
  align-items: center;
}

.app-h-80 {
  height: 80px;
}

.app-h-66 {
  height: 66px;
}

.app-py-36 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.app-px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.app-py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.app-px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.app-py-42 {
  padding-top: 42px;
  padding-bottom: 42px;
}

.app-py-62 {
  padding-top: 62px;
  padding-bottom: 62px;
}

.app-mr-9 {
  margin-right: 9px;
}

.app-px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.app-pr-169 {
  padding-right: 169px;
}

.app-pl-60 {
  padding-left: 60px;
}

.app-py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.app-py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.app-border-radius-18 {
  border-radius: 18px;
}

.app-mr-20 {
  margin-right: 12px;
}

.app-mb-30 {
  margin-bottom: 30px;
}

.app-mb-46 {
  margin-bottom: 46px;
}

.app-whitespace-nowrap {
  white-space: nowrap;
}

.app-w-521 {
  width: 521px;
}

.app-mr-200 {
  margin-right: 200px;
}

.app-w-100pcnt {
  width: 100%;
}

.app-h-100pcnt {
  height: 100%;
}

.app-mr-29 {
  margin-right: 29px;
}

.app-mb-90 {
  margin-bottom: 90px;
}

.app-d-inline-block {
  display: inline-block;
}

.app-gap-30 {
  gap: 30px;
}

.app-gap-10 {
  gap: 10px;
}

.app-mw-500 {
  max-width: 500px;
}

.app-mb-65 {
  margin-bottom: 65px;
}

.app-mb-44 {
  margin-bottom: 44px;
}

.app-w-133 {
  width: 133px;
}

.app-h-118 {
  height: 118px;
}

.app-w-86 {
  width: 86px;
}

.app-h-163 {
  height: 163px;
}

.app-mb-57 {
  margin-bottom: 57px;
}

.app-mb-60 {
  margin-bottom: 60px;
}

.app-pl-80 {
  padding-left: 80px;
}

.app-pr-31 {
  padding-right: 31px;
}

.app-mt-52 {
  margin-top: 52px;
}

.app-mt-15 {
  margin-top: 12px;
}

.app-mb-37 {
  margin-bottom: 37px;
}

.app-mb-75 {
  margin-bottom: 75px;
}

.app-w-160 {
  width: 160px;
}

.app-h-128 {
  height: 128px;
}

.app-mb-68 {
  margin-bottom: 68px;
}

.app-w-50pcnt {
  width: 50%;
}

.app-w-40pcnt {
  width: 40%;
}

.app-w-60pcnt {
  width: 60%;
}

.app-h-100vh {
  height: 100vh;
}

.app-mb-86 {
  margin-bottom: 86px;
}

.app-mb-100 {
  margin-bottom: 100px;
}

.app-mt-36 {
  margin-top: 36px;
}

.app-cursor-pointer {
  cursor: pointer;
}

.app-mt-90 {
  margin-top: 90px;
}

.app-mt-52 {
  margin-top: 52px;
}

.app-mt-35 {
  margin-top: 35px;
}

.app-font-20 {
  font: normal normal normal 20px/20px 'myriad-pro';
}

.app-font-40 {
  font-size: 40px;
}

.app-mb-189 {
  margin-bottom: 189px;
}

.app-uppercase {
  text-transform: uppercase;
}

.app-weight-600 {
  font-weight: 600;
}

.app-w-33 {
  width: 33px;
}

.app-position-relative {
  position: relative;
}

.app-h-508 {
  height: 508px;
}

.app-fit-cover {
  object-fit: cover;
}

.app-mb-86 {
  margin-bottom: 86px;
}

.app-mt-64 {
  margin-top: 64px;
}

.app-flex-wrap {
  flex-wrap: wrap;
}

.app-w-1227 {
  width: 1227px;
}

.app-mw-none {
  max-width: none;
}

.app-h-746 {
  height: 746px;
}

.app-text-align-right {
  text-align: right;
}

.app-mt-33 {
  margin-top: 33px;
}

.app-mr-38 {
  margin-right: 38px;
}

.app-ml-99 {
  margin-left: 99px;
}

.app-mb-26 {
  margin-bottom: 26px;
}

.app-ml-28 {
  margin-left: 28px;
}

.app-mb-20 {
  margin-bottom: 20px;
}

.app-ml-40 {
  margin-left: 40px;
}

.app-w-975 {
  width: 975px;
}

.app-mt-26 {
  margin-top: 26px;
}

.app-mb-7 {
  margin-bottom: 7px;
}

.app-mb-32 {
  margin-bottom: 32px;
}

.app-mb-36 {
  margin-bottom: 36px;
}

.app-w-130 {
  width: 130px;
}

.app-h-31 {
  height: 31px;
}

.app-color-primary-green {
  color: $primary-green-color;
}

.app-color-secondary-yellow {
  color: $secondary-yellow-color;
}

.app-mt-43 {
  margin-top: 43px;
}

.app-color-primary-black2 {
  color: $primary-black-color2;
}

.app-font-16-22 {
  font: normal normal normal 16px/22px 'myriad-pro';
}

.app-w-170 {
  width: 170px;
}

.app-ml-18 {
  margin-left: 18px;
}

.app-h-262 {
  height: 262px;
}

.app-w-20 {
  width: 20px;
}

.app-flex-1 {
  flex: 1;
}

.app-font-12 {
  font-size: 12px;
}

.app-gap-20 {
  gap: 20px;
}

.app-gap-50 {
  gap: 50px;
}

.app-gap-82 {
  gap: 82px;
}

.app-mb-10 {
  margin-bottom: 10px;
}

.app-gap-15 {
  gap: 15px;
}

.app-gap-5 {
  gap: 5px;
}

.app-mt-32px {
  margin-top: 32px;
}

.app-w-212 {
  width: 212px;
}

.app-bg-white {
  background-color: $primary-white-color;
}

.app-border-radius-top-12px {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.app-border-radius-bottom-12px {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.app-min-h-20 {
  min-height: 20px;
}

.app-mt--12 {
  margin-top: -12px;
}

.app-pl-20 {
  padding-left: 20px;
}

.app-h-30 {
  height: 30px;
}

.app-gap-162 {
  gap: 162px;
}

.app-font-24 {
  font-size: 24px;
}

.app-text-capitalize {
  text-transform: capitalize;
}

.app-color-primary-black {
  color: $primary-black-color;
}

.app-justify-content-end {
  justify-content: flex-end;
}

.app-py-23 {
  padding-top: 23px;
  padding-bottom: 23px;
}

.app-px-34 {
  padding-left: 34px;
  padding-right: 34px;
}

.app-gap-42 {
  gap: 42px;
}

.app-mh-212 {
  min-height: 212px;
}

.app-border-radius-12 {
  border-radius: 12px;
}

.app-w-225 {
  width: 225px;
}

.app-mt-37 {
  margin-top: 37px;
}

.app-mt-20 {
  margin-top: 20px;
}


.app-pt-99 {
  padding-top: 99px;
}

.app-lh-28 {
  line-height: 28px;
}

.app-d-none {
  display: none;
}

.app-color-secondary-red {
  color: $secondary-red-color;
}

.app-pt-40 {
  padding-top: 40px;
}

.app-pt-65 {
  padding-top: 65px;
}

.app-mt-30 {
  margin-top: 30px;
}

.app-justify-content-space-around {
  justify-content: space-around;
}

.app-mr-45 {
  margin-right: 45px;
}

.app-w-82 {
  width: 82px;
}

.app-px-44 {
  padding-left: 44px;
  padding-right: 44px;
}

.app-pt-13 {
  padding-top: 13px;
}

.app-p-0 {
  padding: 0;
}

.app-pr-57 {
  padding-right: 57px;
}

.app-w-120 {
  width: 120px;
}

.app-h-15 {
  height: 15px;
}

.app-mt-11 {
  margin-top: 11px;
}

.app-mw-539 {
  max-width: 539px;
}

.app-font-21 {
  font-size: 21px;
}

.app-text-wrap {
  text-wrap: wrap;
}

.app-gap-50 {
  gap: 50px;
}

.app-font-26 {
  font-size: 26px;
}

.app-max-width-460 {
  max-width: 460px;
}

.app-min-height-320 {
  min-height: 320px;
}

.app-h-50pcnt {
  height: 50%;
}

.app-h-32pcnt {
  height: 32%;
}

.app-max-width-360 {
  max-width: 360px;
}

.app-bg-primary-white-color {
  background-color: $primary-white-color;
}

.app-p-30 {
  padding: 30px;
}

.app-pl-51 {
  padding-left: 51px;
}

.app-border-bottom-black {
  border-bottom: 3px solid $primary-black-color;
}

.app-h-100 {
  height: 100px;
}

.app-w-200 {
  width: 200px;
}

.app-position-absolute {
  position: absolute;
}

.app-r-15 {
  right: 15px;
}

.app-p-12 {
  padding: 12px;
}

.app-w-300 {
  width: 300px;
}

.app-gap-0 {
  gap: 0;
}

.app-justify-space-around {
  justify-content: space-around;
}

.app-rotate-180 {
  transform: rotate(180deg);
}

.app-z-index-1 {
  z-index: 1;
}

.app-r-0 {
  right: 0;
}

.app-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &::read-only {
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}

.app-py-25-important {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.app-pl-5 {
  padding-left: 5px;
}

.app-overflow-scroll {
  overflow: scroll;
}

.app-font-28 {
  font-size: 28px;
}
