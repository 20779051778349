.app-copied-message {
  position: absolute;
  right: -25px;
  top: 24px;
  background: #EDEDED;
  border-radius: 6px;
  padding: 5px 20px;
}

.app-copied-message-triangle {
  position: absolute;
  right: 8px;
  top: 16px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #EDEDED;
}
