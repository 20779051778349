@import '../../../assets/styles/variables.scss';

.appTablePagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }

  & > div {
    margin-left: 7px;
  }

  .appTablePaginationPage {
    font: normal normal 600 18px/18px 'myriad-pro';
    color: $primary-black-color;
    margin-right: 7px;
    cursor: pointer;

    &.appTablePaginationPageActive {
      color: $primary-white-color;
      background-color: $primary-green-color;
      width: 24px;
      line-height: 24px;
      border-radius: 50%;
      text-align: center;
    }
  }

}
